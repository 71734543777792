
<template>
  <div>家庭积分</div>
  </template>
   
   <script>

  export default {
    components: {},
    data() {
      return {

      };
    },
  
    methods: {},
  
    created() {},
  
    mounted() {},
  };
  </script>
   <style lang='less' scoped>
  </style>